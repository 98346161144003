/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// ---------------------------------------------------------

import { useState, useEffect, useRef } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import { parameterize } from "@lib/utils"
import Image from "@components/image"

// ---------------------------------------------------------

import {
  company,
  dropdown,
  dropdown_label,
  dropdown_label_svg,
  dropdown_label_svg_is_showing,
  dropdown_menu,
  full_nav_is_hiding,
  full_nav_is_showing,
  featured_image,
  featured_link,
  featured_menu_item,
  icon_platform,
  icon_new_tab,
  link_new_tab,
  link_ai,
  label_is_link,
  menu_column,
  menu_column_header,
  mobile_accordion_menu,
  mobile_accordion_menu_is_showing,
  partners,
  product,
  resources,
  search_is_showing,
  secondary_menu_column_header,
  solutions
} from "./styles.module.scss"

const themeOptions = {
  company: company,
  partners: partners,
  product: product,
  resources: resources,
  solutions: solutions
}

// ---------------------------------------------------------

const Dropdown = (props) => {
  let { items, url, label, searchIsActive } = props

  // -------------------------------------------------------

  const router = useRouter()
  const dropdownRef = useRef()

  // -------------------------------------------------------

  const [isShowing, setDropDownIsShowing] = useState(false)
  const [isMobileAccordionOpen, setMobileAccordionOpen] = useState(false)

  // -------------------------------------------------------

  const mediaIsMediumHighest = useMediaQuery({
    query: `(max-width: 1140px)`
  })

  // -------------------------------------------------------

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropDownIsShowing(false)
    }
  }

  useEffect(() => {
    setDropDownIsShowing(false)
  }, [router.query, router.pathname])

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick)
    return () => document.removeEventListener("click", handleOutsideClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // -------------------------------------------------------

  const dropdownThemeClassName = label.toLowerCase()

  const dropdownClasses = classNames(dropdown, {
    [full_nav_is_hiding]: !mediaIsMediumHighest && !isShowing,
    [full_nav_is_showing]: !mediaIsMediumHighest && isShowing,
    [search_is_showing]: !mediaIsMediumHighest && searchIsActive,
    [themeOptions[dropdownThemeClassName]]: themeOptions[dropdownThemeClassName]
  })

  // -------------------------------------------------------

  const shouldBeDisabled = () => {
    if (!mediaIsMediumHighest) {
      return searchIsActive
    } else {
      return false
    }
  }

  // -------------------------------------------------------

  const toggleMobileAccordion = () => {
    setMobileAccordionOpen(!isMobileAccordionOpen)
  }

  const handleClick = (category = "nav", currentUrl, label, destinationUrl) => {
    window._paq
      ? window._paq.push([
          "trackEvent",
          category,
          `Current Page: ${currentUrl}`,
          `${label} - ${destinationUrl}`
        ])
      : null

    // eslint-disable-next-line
    dataLayer.push({
      event: category,
      current_url: currentUrl,
      destination_url: destinationUrl
    })
  }

  // -------------------------------------------------------

  const dropdownMenuItems = (item, index) => {
    const dropdownMenuItemsClassName = item.heading
      ? classNames(menu_column, parameterize(item.heading.toLowerCase()))
      : menu_column

    return (
      <li className={dropdownMenuItemsClassName} key={`menu-items-${index}`}>
        <ul>
          {item.heading && (
            <li tabIndex="-1" className={menu_column_header}>
              {item.heading}
            </li>
          )}

          {item.links.map((child) => {
            const isExternal = child.url && child.url.match(/^http/)

            const dropdownMenuLink = (link) => (
              <Link
                href={link.url}
                type="button"
                tabIndex="0"
                className={link.image ? featured_menu_item : null}
                target={isExternal ? "_blank" : "_self"}
                rel={isExternal ? "noreferrer" : "none"}
                onClick={() => handleClick("nav", router.asPath, link.label, link.url)}
              >
                <>
                  {link.image && (
                    <div className={featured_image}>
                      <Image
                        src={link.image}
                        alt={link.imageDescription}
                        width={300}
                        height={300}
                      />
                    </div>
                  )}
                  {link.icon && (
                    <div className={icon_platform}>
                      <ReactSVG src={link.icon} alt={link.iconDescription} width={30} height={30} />
                    </div>
                  )}
                  <div className={link.image ? featured_link : link.isAiLink ? link_ai : null}>
                    <span className={isExternal ? link_new_tab : null}>
                      {link.label}
                      {isExternal && (
                        <ReactSVG
                          className={icon_new_tab}
                          src="/icons/external-link.svg"
                          alt="Open in a new tab"
                        />
                      )}
                    </span>
                    {link.description && <p>{link.description}</p>}
                  </div>
                </>
              </Link>
            )

            // -----------------------------------------------

            return (
              <li key={child.label}>
                {child.url && dropdownMenuLink(child)}
                {child.children && (
                  <ul>
                    {child.children.map((item) => (
                      <li key={item.label}>{dropdownMenuLink(item)}</li>
                    ))}
                  </ul>
                )}
              </li>
            )
          })}

          {item.secondaryHeading && (
            <li
              tabIndex="-1"
              className={classNames(menu_column_header, secondary_menu_column_header)}
            >
              {item.secondaryHeading}
            </li>
          )}

          {item.secondaryLinks &&
            item.secondaryLinks.map((child, index) => {
              const dropdownMenuLink = (link) => (
                <Link
                  href={link.url}
                  type="button"
                  tabIndex="0"
                  target="_self"
                  rel="none"
                  onClick={() => handleClick("nav", router.asPath, link.label, link.url)}
                >
                  <>
                    <div className={link.image ? featured_link : null}>
                      <span>{link.label}</span>
                      {link.description && <p>{link.description}</p>}
                    </div>
                  </>
                </Link>
              )

              // -----------------------------------------------

              return <li key={index}>{child.url && dropdownMenuLink(child)}</li>
            })}
        </ul>
      </li>
    )
  }

  // ---------------------------------------------------------

  return (
    <div className={dropdownClasses}>
      {url && (
        <Link
          onClick={() => handleClick("nav", router.asPath, label, url)}
          className={classNames(dropdown_label, label_is_link)}
          href={url}
        >
          {label}
        </Link>
      )}

      {items && (
        <div ref={dropdownRef}>
          <button
            tabIndex="0"
            onFocus={() => setDropDownIsShowing(true)}
            onBlur={() => setDropDownIsShowing(false)}
            onClick={!mediaIsMediumHighest ? () => setDropDownIsShowing(true) : null}
            onMouseEnter={!mediaIsMediumHighest ? () => setDropDownIsShowing(true) : null}
            onMouseLeave={!mediaIsMediumHighest ? () => setDropDownIsShowing(false) : null}
            disabled={!mediaIsMediumHighest ? shouldBeDisabled() : null}
          >
            <div
              className={dropdown_label}
              onClick={mediaIsMediumHighest ? () => toggleMobileAccordion() : null}
            >
              {label}
              <div
                className={classNames(dropdown_label_svg, {
                  [dropdown_label_svg_is_showing]: isMobileAccordionOpen
                })}
              >
                <ReactSVG src={`/icons/angle-down.svg`} alt="" />
              </div>
            </div>

            <ul
              className={classNames(dropdown_menu, {
                [mobile_accordion_menu]: !isMobileAccordionOpen,
                [mobile_accordion_menu_is_showing]: isMobileAccordionOpen
              })}
            >
              {items &&
                items.map((item, index) => {
                  return dropdownMenuItems(item, index)
                })}
            </ul>
          </button>
        </div>
      )}
    </div>
  )

  // -------------------------------------------------------
}

Dropdown.propTypes = {
  /**
   * Specifies an array the dropdown's children
   */
  items: PropTypes.array,

  /**
   * Specifies the link of the dropdown item, if it exists.
   */
  url: PropTypes.string,

  /**
   * * Specifies the dropdown label
   */
  label: PropTypes.string.isRequired,

  /**
   * Specifies a boolean value passed down from the Header
   * to inform each child know when the search bar is activated
   */
  searchIsActive: PropTypes.bool
}

export default Dropdown
