/* eslint-disable jsx-a11y/label-has-associated-control */
// ---------------------------------------------------------

import { useEffect, useState, useCallback, useMemo } from "react"
import Link from "next/link"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactSVG } from "react-svg"
import { useRouter } from "next/router"

// ---------------------------------------------------------

import Navigation from "./navigation"
import Search from "./search"

// ---------------------------------------------------------

import {
  header_desktop,
  header_desktop_left,
  header_desktop_right,
  header_mobile,
  header_mobile_nav,
  header,
  cta_primary,
  cta_secondary,
  cta_login,
  logo,
  menu_button_container,
  dots,
  dot,
  x,
  circle,
  clicked,
  menu_button,
  menu_container,
  navigation_container,
  navigation_is_showing
} from "./styles.module.scss"

// ---------------------------------------------------------

const Header = ({
  primaryCta,
  secondaryCta,
  login,
  menu,
  hideNavLeft,
  hideNavRight,
  customCta
}) => {
  const router = useRouter()

  const [navIsActive, setNavIsActive] = useState(false)
  const [searchIsActive, setSearchIsActive] = useState(false)
  const [showNavLinks, setShowNavLinks] = useState(false)

  // -------------------------------------------------------
  const toggleNavVisiblity = () => setNavIsActive((prev) => !prev)

  const resetMenu = useCallback(() => setNavIsActive(false), [])

  // useEffect(() => {
  //   document.body.style.overflow = navIsActive ? "hidden" : "auto"
  // }, [navIsActive])

  useEffect(() => {
    if (router.isReady) {
      setShowNavLinks(true)
      router.events.on("routeChangeComplete", resetMenu)
      return () => {
        router.events.off("routeChangeComplete", resetMenu)
      }
    }
  }, [router, resetMenu])

  const handleSearchIsActive = (value) => {
    setSearchIsActive(value)
  }

  // -------------------------------------------------------

  const amperityLogo = useMemo(
    () => (
      <Link
        href="/"
        aria-label="Amperity Home"
        className={logo}
        onClick={() => handleClick("nav", router.asPath, "", "/")}
      >
        <ReactSVG src={`/svg/amperity-logo.svg`} />
      </Link>
    ),
    [router.asPath]
  )

  const handleClick = (category = "nav", currentUrl, label, destinationUrl) => {
    if (window._paq) {
      window._paq.push([
        "trackEvent",
        category,
        `Current Page: ${currentUrl}`,
        `${label} - ${destinationUrl}`
      ])
    }

    // eslint-disable-next-line
    dataLayer.push({
      event: category,
      current_url: currentUrl,
      destination_url: destinationUrl
    })
  }

  const secondaryLink = useMemo(
    () => (
      <Link href={secondaryCta?.url} onClick={resetMenu}>
        <button
          onClick={() => handleClick("nav", router.asPath, secondaryCta?.label, secondaryCta?.url)}
          className={cta_secondary}
        >
          {secondaryCta?.label || "Demo"}
        </button>
      </Link>
    ),
    [secondaryCta, router.asPath, resetMenu]
  )

  const primaryLinkLabel = customCta?.label || primaryCta?.label
  const primaryLinkUrl = customCta?.url || primaryCta?.url
  const primaryLink = useMemo(
    () => (
      <Link href={primaryLinkUrl} onClick={resetMenu}>
        <button
          onClick={() => handleClick("nav", router.asPath, primaryLinkLabel, primaryLinkUrl)}
          className={cta_primary}
        >
          {primaryLinkLabel}
        </button>
      </Link>
    ),
    [primaryLinkLabel, primaryLinkUrl, router.asPath, resetMenu]
  )

  // ---------------------------------------------------------

  const mobileMenu = (
    <div aria-label="menu" className={menu_button}>
      <span>Menu</span>
      <div className={menu_button_container}>
        <button
          data-testid="mobile-menu"
          aria-label="Mobile menu open/close button"
          className={navIsActive ? clicked : null}
          onClick={toggleNavVisiblity}
        >
          <div className={dots}>
            <div className={navIsActive ? x : circle}>
              <div className={dot}></div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )

  const headerRight = (
    <div className={header_desktop_right}>
      {!hideNavRight && <Search handleSearchIsActive={handleSearchIsActive} />}
      {!hideNavRight && (
        <a className={cta_login} href={login.buttonUrl} target="_blank" rel="noreferrer noopener">
          {login.buttonLabel}
        </a>
      )}
      {!hideNavRight && secondaryLink}
      {hideNavRight ? (customCta?.label && customCta?.url ? primaryLink : null) : primaryLink}
    </div>
  )

  const navigation = (
    <Navigation
      className={navigation_container}
      links={menu}
      navigationIsShowing={navIsActive}
      searchIsActive={searchIsActive}
      buttons={[secondaryLink, primaryLink]}
      onSearchActiveChange={handleSearchIsActive}
    />
  )

  // -------------------------------------------------------

  return (
    <header
      className={classNames(header, {
        [navigation_is_showing]: navIsActive
      })}
    >
      <div className={menu_container}>
        {/* Mobile menu */}
        <div className={header_mobile}>
          <div className={header_mobile_nav}>
            {amperityLogo}
            {mobileMenu}
          </div>
          {showNavLinks && navigation}
        </div>
        {/* Desktop menu */}
        <div className={header_desktop}>
          <div className={header_desktop_left}>
            {amperityLogo}
            {showNavLinks && (hideNavLeft ? null : navigation)}
          </div>
          {headerRight}
        </div>
      </div>
    </header>
  )
}

// ---------------------------------------------------------

Header.propTypes = {
  /**
   * An object used to build the button
   */
  primaryCta: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),

  /**
   * An object used to build a right aligned link
   */
  secondaryCta: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),

  /**
   * An object used to build a right aligned link
   */
  login: PropTypes.shape({
    buttonLabel: PropTypes.string.isRequired,
    buttonUrl: PropTypes.string.isRequired
  }),

  /**
   * An array of main navigation links.
   * This prop is passed to the Navigation component
   */
  menu: PropTypes.array.isRequired,

  /**
   * Hides the left-hand links, eg Products ... Partners
   */
  hideNavLeft: PropTypes.bool,

  /*
   *  Hides the right-hand items, eg search bar ... Contact Sales.
   */
  hideNavRight: PropTypes.bool,

  /**
   * Overrides the primary button.
   * When hideLeftNav and hideRightNav are true, this is the only button that appears in nav.
   */
  customCta: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string
  })
}

// ---------------------------------------------------------

export default Header
