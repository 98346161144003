import { useEffect } from "react"
import PropTypes from "prop-types"
import Head from "next/head"
import classNames from "classnames"
import { Analytics } from "@vercel/analytics/react"
import { useRouter } from "next/router"
import { parameterize, transformFlatArrToObj, removeUndefinedAndNull } from "../lib/utils"
import LazyLoad from "react-lazyload"

// ---------------------------------------------------------

import Header from "./header"
import Footer from "./footer"
import HeaderFixtures from "./header/fixtures"
import FooterFixtures from "./footer/fixtures"

// ---------------------------------------------------------

import AlertBar from "@components/alert-bar"
import DebugMediaQueries from "./debug-media-queries"

// ---------------------------------------------------------

const defaultExcerpt =
  "Supercharge your customer initiatives from all your disparate data sources to build a complete and actionable Customer 360."

// ---------------------------------------------------------

import { preview_alert, is_homepage } from "./styles.module.scss"

const FEATURED_IMAGE = {
  url: "/img/Amperity_Meta.jpg",
  description: "Amperity logo on a black datawave background"
}

const Layout = ({
  children,
  className,
  customMeta,
  excerpt,
  featuredImage = FEATURED_IMAGE,
  pageTitle,
  preview = false,
  canonical,
  navSettings
}) => {
  if (Array.isArray(customMeta)) customMeta = transformFlatArrToObj(customMeta, "name", "content")
  excerpt = excerpt && excerpt.length < 34 ? `${excerpt} | ${defaultExcerpt}` : excerpt

  const metaObject = {
    "og:title": pageTitle,
    "og:image": featuredImage?.url || featuredImage,
    "og:description": excerpt,
    "og:site_name": "Amperity",
    "twitter:card": "summary_large_image",
    description: excerpt,
    title: pageTitle
  }

  const refinedCustomMeta = removeUndefinedAndNull(customMeta)
  const meta = removeUndefinedAndNull({ ...metaObject, ...refinedCustomMeta })

  // -------------------------------------------------------

  const router = useRouter()

  // -----------------------------------------------------

  let layoutClasses = className

  if (router.pathname === "/" || router.asPath === "/homepage") {
    layoutClasses = classNames(className, is_homepage)
  }

  // -------------------------------------------------------

  const titleString = "Amperity"
  const title = meta.title ? `${meta.title} | ${titleString}` : titleString

  const setCookie = (cookieName, cookieValue, expiryDays = 14) => {
    const d = new Date()
    d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000)
    const cookieExpiry = "expires=" + d.toUTCString()
    document.cookie = `${cookieName}=${cookieValue}; expires=${cookieExpiry}`
  }

  useEffect(() => {
    if (!router.isReady) return
    const utmObj = router.query
    const utmKeys = Object.keys(utmObj)

    //list out the utms params we want to be tracking
    const trackedUtms = ["utm_medium", "utm_source", "utm_campaign", "utm_content", "utm_term"]

    // check if the current utms contain any of the tracked ones, if they exist then want to set the new ones as cookies, and clear out the others
    if (trackedUtms.some((e) => utmKeys.includes(e))) {
      trackedUtms.forEach((utm) => {
        // if the utm query exists, set it in a cookie, otherwise clear it
        if (utmKeys.includes(utm)) {
          setCookie(utm, utmObj[utm])
        } else {
          setCookie(utm, "", -1)
        }
      })
    }
  }, [router])

  // -------------------------------------------------------

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
        {canonical ? <link rel="canonical" href={canonical} /> : null}

        {meta &&
          Object.keys(meta).map((key) => {
            if (!["title"].includes(key)) {
              return <meta key={parameterize(key)} property={key} name={key} content={meta[key]} />
            }
          })}

        <title>{title}</title>
      </Head>

      <Header {...HeaderFixtures.default} {...navSettings} />

      <main className={layoutClasses}>{children}</main>

      <Analytics />

      <LazyLoad offset={300} once>
        <Footer {...FooterFixtures} preview={preview} />
      </LazyLoad>

      {preview && process.env.NODE_ENV !== "development" && (
        <div className={preview_alert}>
          <AlertBar
            alertText="Viewing page in Preview Mode"
            buttonLabel="Exit Preview Mode"
            buttonLink="/api/disable-draft"
            backgroundColor="cement"
          ></AlertBar>
        </div>
      )}

      <DebugMediaQueries />
      <div dangerouslySetInnerHTML={{ __html: `<!-- amperity likes to run -->` }}></div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  preview: PropTypes.bool,
  pageTitle: PropTypes.string,
  excerpt: PropTypes.string,
  featuredImage: PropTypes.shape({
    description: PropTypes.string,
    height: PropTypes.number,
    url: PropTypes.string,
    width: PropTypes.number
  }),

  canonical: PropTypes.string,
  customMeta: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  /**
   * Settings that customize navigation.
   * hideNavLeft: Hides the left-hand links, eg Products ... Partners
   * hideNavRight: Hides the right-hand items, eg search bar ... Contact Sales.
   * customCta: Overrides the default CTA. When other two options are true, this is the only button that appears in nav.
   */
  navSettings: PropTypes.shape({
    hideNavLeft: PropTypes.bool,
    hideNavRight: PropTypes.bool,
    customCta: PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string
    })
  })
}

export default Layout
