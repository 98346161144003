/* eslint-disable @next/next/no-img-element */

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import { svg_container } from "./styles.module.scss"

// ---------------------------------------------------------

const SVGs = ({ className, src, title }) => {
  const classes = classNames(svg_container, {
    [className]: className
  })

  //TODO implement Link

  return (
    <ReactSVG
      aria-label={title}
      beforeInjection={(src) => {
        const titleElement = document.createElementNS("http://www.w3.org/2000/svg", "title")
        titleElement.innerHTML = title
        src.prepend(titleElement)
      }}
      className={classes}
      role="img"
      src={src}
    />
  )
}

SVGs.propTypes = {
  /**
   * Specifies the SVG src
   */
  src: PropTypes.string,

  /**
   * A title for the svg
   */
  title: PropTypes.string
}

export default SVGs
